exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aktualnosci-jsx": () => import("./../../../src/pages/aktualnosci.jsx" /* webpackChunkName: "component---src-pages-aktualnosci-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-pokazy-jsx": () => import("./../../../src/pages/pokazy.jsx" /* webpackChunkName: "component---src-pages-pokazy-jsx" */),
  "component---src-pages-zajecia-jsx": () => import("./../../../src/pages/zajecia.jsx" /* webpackChunkName: "component---src-pages-zajecia-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

